.answers-container__answer-4 {
    display: flex;
}

.answers-container__answer-4--icon {
    cursor: pointer;
    width: 70px;
}

.answers-container__answer-mood {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.mood {
    display: none;
}

    .mood ~ span {
        font-size: 3rem;
        filter: grayscale(100);
        cursor: pointer;
        transition: 0.5s;
    }

    .mood:checked ~ span {
        filter: grayscale(0);
        font-size: 3.5rem;
    }

.mood-container {
    margin-left: -15px;
}