.checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

.checkbox {
    cursor: pointer;
}

    .checkbox .checkbox-content {
        display: -webkit-box;
        display: -ms-flexbox;
        padding: 10px;
        margin-bottom: 5px;
        -webkit-transition: -webkit-box-shadow 0.4s;
        transition: -webkit-box-shadow 0.4s;
        -o-transition: box-shadow 0.4s;
        transition: box-shadow 0.4s;
        transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
        /*    position: relative; TO SHOW RADIO BUTTON RIGHT */
    }

        .checkbox .checkbox-content:hover {
            -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
            box-shadow: 0px 3px 5px 0px #e8e8e8;
        }

    .checkbox input[type="checkbox"]:checked + .checkbox-content {
        -webkit-transition: ease-in 0.3s;
        -o-transition: ease-in 0.3s;
        transition: ease-in 0.3s;
    }

@media screen and (min-width: 768px) {
    .checkbox-content {
        width: 300px;
    }

    .checkbox input[type="checkbox"]:checked + .checkbox-content:after {
        top: 45%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@media screen and (max-width: 768px) {
    .checkbox input[type="checkbox"]:checked + .checkbox-content:after {
        top: 20px;
        right: 10px;
    }

    .checkbox {
        width: 100%;
    }

        .checkbox .checkbox-content {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
        }
}