.box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255,255,255,0.2);
    padding: 35px;
    border: 2px solid #fff;
    background-clip: padding-box;
    text-align: center;
}

.button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06D85F;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

    .button:hover {
        background: #06D85F;
    }

.intro-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 1;
}

    .intro-overlay:target {
        visibility: visible;
        opacity: 1;
    }

.intro-popup {
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    width: 50%;
    /*height: 90%;*/
    position: relative;
    transition: all 2s ease-in-out;
    text-align: center;
}

    .intro-popup h2 {
        margin-top: 0;
        color: #333;
    }

    .intro-popup .close {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
    }

        .intro-popup .close:hover {
            color: #06D85F;
        }

    .intro-popup .content {
        max-height: 30%;
        overflow: auto;
    }

@media screen and (max-width: 768px) {
    .box {
        width: 70%;
    }

    .intro-popup {
        width: 90%;
    }
}

.intro-image-container {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.intro-button-container {
    margin-top: 25px;
}

    .intro-button-container button {
        width: 200px;
        height: 50px;
        cursor: pointer;
        pointer-events: all;
        opacity: 1;
        font-size: 24px;
    }

@media screen and (max-width: 768px) {
    .intro-button-container button {
        width: 100%;
    }
}

.hide-close-message {
    display: none;
}

.show-close-message {
    display: block;
    cursor: not-allowed;
}
