body {
    min-height: 100vh;
}

.head-container {
    min-height: 150px;
    text-align: center;
    margin: 0 0 20px 0;
    padding: 3% 2% 2% 2%;
}

.footer-container {
    min-height: 25px;
    text-align: center;
    padding: 10px 5% 10px 5%;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.questions-container {
    padding: 0 5% 0 5%;
    margin-bottom: 60px;
}

.questions-container__question > img {
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .questions-container {
        padding: 0 15% 0 15%;
    }
}

.questions-container__question {
    margin: 0 0 40px 0;
    border-style: dashed;
    padding: 10px 15px 10px 15px;
}

    .questions-container__question > img {
        width: 50%;
    }

@media screen and (max-width: 768px) {
    .questions-container__question > img {
        width: 100%;
    }
}

textarea {
    width: 100%;
}

@media screen and (max-width: 768px) {
    option,
    select,
    textarea {
        min-width: 100%;
    }
}

select,
input {
    width: 300px;
}

.show_question {
    display: block;
    margin-bottom: 40px;
    margin-top: 10px;
}

.hide_question {
    display: none;
}

.question-required:after {
    content: "*";
    color: red;
    margin-left: -10px;
    position: absolute;
}

.questions-container__question {
    min-width: 300px;
    background: white;
}

.active-button {
    width: 100%;
    height: 50px;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
}

.disable-button {
    cursor: not-allowed;
    pointer-events: none;
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    background: #F0F0F0;
    opacity: 0.5;
}

.supported-language-container {
    text-align: right;
    margin: 0 0 40px 0;
}

input[type="datetime-local"],
input[type="number"],
input[type="date"],
input[type="time"],
select {
    height: 35px;
}

.head-container__header-image {
}

.authorization-form-container {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

    .authorization-form-container > input {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 10px;
    }

@media screen and (max-width: 768px) {
    input[type="datetime-local"],
    input[type="number"],
    input[type="date"],
    input[type="time"],
    textarea,
    option,
    span {
        width: 100%;
    }
}

.authorization-form-container > span {
    font-size: 40px;
}

.authorization-form-container > button {
    font-size: 30px;
    background: #f2f2f2;
    font-weight: 700;
}

.authorization-form-container > input {
    height: 40px;
}

.authorization {
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    width: 50%;
    /*height: 90%;*/
    position: relative;
    transition: all 2s ease-in-out;
    text-align: center;
    background-color: white;
}

    .authorization .close {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
    }

        .authorization .close:hover {
            color: #06D85F;
        }

    .authorization .content {
        max-height: 30%;
        overflow: auto;
    }

.send-button {
    font-size: 35px;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .box {
        width: 70%;
    }

    .authorization {
        width: 100%;
        margin: 0;
        position: absolute;
        bottom: 0;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .authorization-form-container > input {
        border-radius: 5px;
    }

    .send-button {
        transition: 0.8s;
        /*position: sticky;
        bottom: 5px;*/
    }
}

.default-icon-message-send {
    font-size: 50px;
    margin-top: 10%;
}